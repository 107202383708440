import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
export default function insertModel(model, mark){
    if(model != ''){
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 14,
                    value: model,
                    mark: mark
                }
            })
            .then(response => {
                if(response.data != -10){
                    Journal(localStorage.getItem('login'), 'Добавлена новая модель авто: ' + model)
                    this.models.push({
                        value: response.data,
                        text: model
                    })
                    this.newModel = ''
                    UIkit.modal("#modal-insert").hide()
                    UIkit.notification({message: localizeFilter('Insert'), pos:'bottom-center'})
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}