import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
export default function changeModel(model, event){
    if(model != ''){
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 13,
                    id: model.value,
                    model: event.target.value
                }
            })
            .then(response => {
                if(response.data != -10){
                    Journal(localStorage.getItem('login'), 'Изменение элемента в списке моделей: ' + model.text + ' -> ' + event.target.value )
                    model.text = event.target.value
                    UIkit.notification({message: localizeFilter('Changed'), pos:'bottom-center'})
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}